import React from 'react';

import LoadingDots from 'common-ui-components/LoadingDots';
import SingularPluralNouns, { getAmountLabel } from 'global/lists/SingularPluralNouns';

import style from 'screens/platform/directory/components/ProfileTabs/components/InteractionsCount/style.module.scss';

interface Props {
  filteredAtomsCount: number;
  loading: boolean;
}

export default function InteractionsCount({ filteredAtomsCount, loading }: Props) {
  // Display 1000+ when filteredAtomsCount hits 1000, as exact counts aren't available yet.
  const title = filteredAtomsCount < 1000
    ? getAmountLabel(filteredAtomsCount, SingularPluralNouns.result)
    : `${filteredAtomsCount}+ ${SingularPluralNouns.result.plural}`;

  return (
    <div className={style.interactionsStatus}>
      {loading ? <LoadingDots /> : title}
    </div>
  );
}
