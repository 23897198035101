import {
  addDays,
  addHours,
  Day,
  endOfWeek,
  formatDistanceToNowStrict,
  getMonth,
  getYear,
  isBefore,
  isToday,
  isYesterday,
  millisecondsToHours,
  millisecondsToMinutes,
  startOfDay,
  startOfWeek,
  subHours,
} from 'date-fns';

import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import subMonths from 'date-fns/subMonths';
import { DateRangeGranularity } from 'screens/platform/contentScreens/AnalyticsScreen/widgets/widgetConfig';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import DateUtils from 'utils/DateUtils';

export const MONDAY_INDEX = 1;

export function getDateUTC(epoch: number): Date {
  const date = new Date(epoch);

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const milliseconds = date.getUTCMilliseconds();

  return new Date(year, month, day, hours, minutes, seconds, milliseconds);
}

export function convertUTCToUserTimezone(dateInUTC: Date): Date {
  const timezoneOffset = dateInUTC.getTimezoneOffset();
  return addMinutes(dateInUTC, timezoneOffset);
}

export function convertUserTimezoneToUTC(dateInUserTimezone: Date): Date {
  const timezoneOffset = dateInUserTimezone.getTimezoneOffset();
  let date = addMinutes(dateInUserTimezone, timezoneOffset);
  const hours = date.getHours();
  // Handle daylight saving time
  if (hours !== 0) {
    if (isDST(date)) {
      date = subHours(date, hours);
    } else {
      date = addHours(date, 24 - hours);
    }
  }
  return date;
}

export function getFormattedDate(dateAsString: string): string {
  const date = new Date(dateAsString);
  const showYear = new Date().getFullYear() !== date.getFullYear();
  const yearFormat = showYear ? ', u' : '';
  const formatPattern = isToday(date) ? 'p' : `MMM dd${yearFormat}, p`;
  return `${isToday(date) ? 'Today, ' : ''}${format(date, formatPattern)}`;
}

export function startOfUTCDay(date: Date): Date {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayOfMonth = date.getDate();
  return new Date(Date.UTC(year, month, dayOfMonth));
}

export function endOfUTCDay(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const dayOfMonth = date.getDate();
  return new Date(Date.UTC(year, month, dayOfMonth, 23, 59, 59, 999));
}

export function startOfWeekInUserTimezone(dateInUTC: Date, weekStartsOn: Day = MONDAY_INDEX): Date {
  const dateInUserTimezone = convertUTCToUserTimezone(dateInUTC);
  return startOfWeek(dateInUserTimezone, { weekStartsOn });
}

export function endOfWeekInUserTimezone(dateInUTC: Date, weekStartsOn: Day = MONDAY_INDEX): Date {
  const dateInUserTimezone = convertUTCToUserTimezone(dateInUTC);
  return endOfWeek(dateInUserTimezone, { weekStartsOn });
}

export function formatDateByGranularity(
  date: Date,
  granularity = DateRangeGranularity.DAY,
): string {
  const nextWeek = addDays(date, 6);
  return granularity === DateRangeGranularity.DAY
    ? format(date, DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH)
    : `${format(date, DateUtils.DateFormat.WEEKDAY_MONTH_DAY_TH)} - ${format(nextWeek, DateUtils.DateFormat.MONTH_DAY_TH)}`;
}

function getDaysInLastMonth(): number {
  const currentMonth = getMonth(new Date());
  const currentYear = getYear(new Date());
  const lastMonthStart = new Date(currentYear, currentMonth - 1, 0);
  const currentMonthStart = new Date(currentYear, currentMonth, 0);
  return differenceInDays(currentMonthStart, lastMonthStart);
}

export function getDatesFilterLabel(
  datesRange: DatesRange,
): string {
  const { from: fromUTC, to: toUTC } = datesRange;
  const from = startOfDay(convertUTCToUserTimezone(fromUTC));
  const to = startOfDay(convertUTCToUserTimezone(toUTC));
  const timePeriodLength = differenceInDays(to, from) + 1;
  const isEndingToday = isSameDay(new Date(), to);
  const daysInLastMonth = getDaysInLastMonth();

  if (isEndingToday && timePeriodLength < daysInLastMonth) {
    if (timePeriodLength === 1) return 'Today';
    return `Last ${timePeriodLength} Days`;
  }

  if (isEndingToday) {
    const exactMonthsAmount = [1, 2, 3].find((months) => {
      const fromExactlyMonthsAgo = subMonths(to, months);
      return isSameDay(from, startOfDay(fromExactlyMonthsAgo));
    });
    if (exactMonthsAmount) {
      return exactMonthsAmount === 1 ? 'Last Month' : `Last ${exactMonthsAmount} Months`;
    }
  }

  const formattedFrom = DateUtils.formatDateByDatesRange(from, false, datesRange);
  const formattedTo = isEndingToday ? 'Today' : DateUtils.formatDateByDatesRange(to, false, datesRange);
  return formattedFrom === formattedTo ? formattedFrom : `${formattedFrom} - ${formattedTo}`;
}

export function formatDateRelativelyToNow(dateAsString: string): string {
  const date = new Date(dateAsString);
  if (isToday(date)) {
    return 'today';
  }
  if (differenceInDays(new Date(), date) < 6) {
    return formatDistanceToNowStrict(date, { addSuffix: true });
  }
  return format(date, 'MMM do, yyyy');
}

export function formatRelativeDateWithTodayYesterday(date: Date): string {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  return formatDateByGranularity(date);
}

// Source https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
function isDST(d: Date) {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export default {
  getFormattedDate,

  DateFormat: {
    MONTH_DAY_TH: 'MMM do',
    WEEKDAY_MONTH_DAY_TH: 'E, MMM do',
    FULL_MONTH_DAY_TH_WEEKDAY: 'MMMM do, EEEE',
    MONTH_DAY: 'M/d',
    YEAR_MONTH_DAY: 'u-MM-dd',
    YEAR_MONTH_DAY_HOUR: 'u-MM-dd-HH',
  },

  /**
   * If the two dates are in different years, then the year is included in the output
   * @param d - The date to be formatted
   * @param isFullMonthName - Whether to include the full month name, or just 3 letters prefix
   * @param datesRange - The dates according to which the year is added/omitted
   */
  formatDateByDatesRange(d: Date, isFullMonthName: boolean, datesRange: DatesRange): string {
    const currentYear = new Date().getFullYear();
    const showYear = [datesRange.from, datesRange.to].some(
      (x) => new Date(x).getFullYear() !== currentYear,
    );

    const yearFormat = showYear ? ', u' : '';
    const monthFormat = isFullMonthName ? 'MMMM' : 'MMM';

    const dateFormat = `${monthFormat} dd${yearFormat}`;
    return format(d, dateFormat);
  },

  getTimeAgoLabel(date: Date): string {
    const now = new Date().getTime();
    const millisAgo = now - date.getTime();
    const daysAgo = Math.round(millisecondsToHours(millisAgo) / 24);
    if (daysAgo >= 1) return `${daysAgo.toFixed(0)}d ago`;

    const hoursAgo = millisecondsToHours(millisAgo);
    if (hoursAgo >= 1) return `${hoursAgo}h ago`;

    const minutesAgo = millisecondsToMinutes(millisAgo);
    if (minutesAgo >= 1) return `${minutesAgo}m ago`;

    return 'Now';
  },

  getDaysAgo(date: Date): number {
    const now = new Date().getTime();
    const millisAgo = now - date.getTime();
    return Math.round(millisecondsToHours(millisAgo) / 24);
  },

  getDatesInRange(datesRange: DatesRange): Date[] {
    const firstDate = startOfDay(datesRange.from);
    const lastDate = startOfDay(datesRange.to);

    const ans: Date[] = [];
    for (
      let date = startOfDay(firstDate);
      isBefore(date, addDays(lastDate, 1));
      date = addDays(date, 1)
    ) {
      ans.push(date);
    }
    return ans;
  },

  convertDatesRangeToUTC(datesRange: DatesRange): DatesRange {
    return {
      from: startOfUTCDay(datesRange.from),
      to: endOfUTCDay(datesRange.to),
    };
  },
};
