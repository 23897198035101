import { PromptPreprocessingResponse } from 'global/api/controller/EnterpriseSearchController';
import { HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { Atom } from 'model/Atom';
import Organization from 'model/Organization';
import { MinimalContact, MinimalPerson } from 'model/Person';
import Tenant from 'model/Tenant';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import {
  DatesRange,
  MasterFilters,
  MasterFiltersApiRequest,
} from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import convertMasterFilters
  from 'screens/platform/cross-platform-components/context/MasterFiltersContext/utils/MasterFiltersApiConversionUtils';

type LimitedSearchResults<T> = {
  results: T[];
  totalResultsCount: number;
}

export type TagWithCategories = {
  tag: string;
  categories: string[];
}

export type SearchResultsResponse = {
  atoms: LimitedSearchResults<Atom>;
  persons: LimitedSearchResults<MinimalPerson>;
  contacts: LimitedSearchResults<MinimalContact>;
  organizations: LimitedSearchResults<Organization>;
  topics: LimitedSearchResults<TagWithCategories>;
};

type SearchRequestBody = {
  filters?: MasterFiltersApiRequest;
  datesRange?: {
    from: number;
    to: number;
  };
  topics?: string[];
  appIds?: number[];
  participantsPersonIds?: string[];
  organizationsIds?: string[];
  directionality?: Directionality;
  preprocessedPrompt?: PromptPreprocessingResponse;
};

const SearchController = {
  async searchQuery(
    tenantId: Tenant['id'],
    query: string,
    {
      filters,
      signal,
      datesRange,
      topics,
      appIds,
      participantsPersonIds,
      organizationsIds,
      directionality,
      preprocessedPrompt,
    }: {
      filters?: MasterFilters;
      signal?: AbortSignal;
      datesRange?: DatesRange;
      topics?: string[];
      appIds?: App[];
      participantsPersonIds?: string[];
      organizationsIds?: string[];
      directionality?: Directionality;
      preprocessedPrompt?: PromptPreprocessingResponse;
    } = {},
  ): HttpResponse<SearchResultsResponse> {
    const requestBody: SearchRequestBody = {
      filters: filters ? convertMasterFilters(filters, true) : undefined,
      datesRange: datesRange ? {
        from: datesRange.from.getTime(),
        to: datesRange.to.getTime(),
      } : undefined,
      topics,
      appIds,
      participantsPersonIds,
      organizationsIds,
      directionality,
      preprocessedPrompt,
    };
    return postRequest('search', requestBody, {
      params: {
        tenantId,
        textToSearch: query,
      },
      signal,
    });
  },
};

export default SearchController;
