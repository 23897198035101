import { getRequest, HttpResponse, postRequest } from 'global/api/platformApiHelpers';
import App from 'global/lists/apps';
import { Atom } from 'model/Atom';
import { SalesforceRecord } from 'model/Salesforce';
import Tenant from 'model/Tenant';
import Directionality from 'screens/platform/cross-platform-components/context/MasterFiltersContext/Directionality';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';

const sourceTypes = ['atom', 'salesforce', 'person', 'organization'] as const;
export type SourceType = typeof sourceTypes[number];

export type AtomSource = {
  type: 'atom';
  data: Atom;
}

export type SalesforceSource = {
  type: 'salesforce';
  data: SalesforceRecord;
}

export type PersonSource = {
  type: 'person';
  data: {
    _id: string;
  };
}

export type OrganizationSource = {
  type: 'organization';
  data: {
    id: string;
  };
}

export type Source = AtomSource | SalesforceSource | PersonSource | OrganizationSource;

export interface CustomFilters {
  datesRange: DatesRange;
  includeUntagged: boolean;
  topics?: string[];
  appIds?: App[];
  participantsPersonIds?: string[];
  organizationsIds?: string[];
  directionality?: Directionality;
}

export type Intent = 'SEARCH' | 'QUESTION' | 'TASK';

export interface EnterpriseSearchResponse {
  sources: Source[];
  answer: string;
  sessionId: string;
  isSuccessfulAnswer: boolean;
  intent: Intent;
  prompt?: string;
  cleanedPromptResult?: CleanedPromptResult;
}

export type FollowupQuestion = {
  followupQuestion: string;
  shortFollowupQuestion: string;
}

export type CleanedPromptResult = {
  cleanedPrompt: string;
  originalPrompt: string;
  confidenceScore: number;
};

export type QuestionSource =
  | 'userInput'
  | 'followupQuestion'
  | 'suggestedQuestion'
  | 'relevantSearchQuestion'
  | 'originalQuestion'
  | 'cleanedQuestion';

interface EntitySummaryResponse {
  data: string;
  sessionId: string;
  isSuccessfulAnswer: boolean;
}

export interface PromptPreprocessingResponse {

}

export function isValidSourceType(sourceType: SourceType) {
  return sourceTypes.includes(sourceType);
}

const EnterpriseSearch = {
  async preprocessPrompt(
    tenantId: Tenant['id'],
    question: string,
    questionSource: QuestionSource,
    signal?: AbortSignal,
    skipPromptCleaning: boolean = false,
  ): Promise<HttpResponse<PromptPreprocessingResponse>> {
    return postRequest(
      'enterpriseSearch/preprocessPrompt',
      { question, questionSource, skipPromptCleaning },
      { params: { tenantId }, signal },
    );
  },

  async enterpriseSearch(
    tenantId: Tenant['id'],
    question: string,
    questionSource: QuestionSource,
    signal?: AbortSignal,
    skipPromptCleaning: boolean = false,
    preprocessedPrompt?: PromptPreprocessingResponse,
  ): Promise<HttpResponse<EnterpriseSearchResponse>> {
    return postRequest(
      'enterpriseSearch',
      {
        question, questionSource, skipPromptCleaning, preprocessedPrompt,
      },
      { params: { tenantId }, signal },
    );
  },

  async getSuggestedQuestions(tenantId: Tenant['id']): HttpResponse<string[]> {
    return getRequest('enterpriseSearch/suggestedQuestions', { tenantId });
  },

  async getFollowupQuestions(
    tenantId: Tenant['id'],
    sessionId: string,
    question: string,
    answer: string,
  ): HttpResponse<FollowupQuestion[]> {
    return postRequest(
      'enterpriseSearch/followupQuestions',
      { sessionId, question, answer },
      { params: { tenantId } },
    );
  },

  async getEntitySummary(
    tenantId: Tenant['id'],
    entityType: 'topics' | 'organizations' | 'people',
    entityId: string,
    filters: CustomFilters,
  ): HttpResponse<EntitySummaryResponse> {
    const { datesRange, ...rest } = filters;
    const customFilters = {
      ...rest,
      datesRange: datesRange ? {
        from: datesRange.from.getTime(),
        to: datesRange.to.getTime(),
      } : undefined,
    };

    return postRequest('entity/summary', { entityType, entityId, customFilters }, { params: { tenantId } });
  },
};

export default EnterpriseSearch;
