import React, { useCallback, useRef, useState } from 'react';

import Api from 'global/api/platformApi';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import ConfigurationContext
  from 'screens/platform/cross-platform-components/context/configuration/ConfigurationContext';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';

export type ConfigType = string | boolean | number;
type ConfigurationsState = Partial<Record<ConfigurationKey, ConfigType>>;

export const defaultConfigValues: Readonly<
  Record<ConfigurationKey, ConfigType>
> = {
  [ConfigurationKey.IS_ORGANIZATIONS_FEATURE_ACTIVATED]: false,
  [ConfigurationKey.IS_SOA_THRESHOLD_BY_ORGANIZATIONS_OBJECTIVE_ACTIVATED]: false,
  [ConfigurationKey.IS_FOLLOW_INTEREST_FEATURE_ACTIVATED]: false,
  [ConfigurationKey.MAX_DISPLAY_TIME_PERIOD_IN_DAYS]: 90,
  [ConfigurationKey.REPORTS_URL]: '',
  [ConfigurationKey.IS_TIME_IN_MEETINGS_FOR_LONG_PERIODS_METRICS_ENABLED]: false,
  [ConfigurationKey.MAX_ATOMS_COUNT]: 1000,
  [ConfigurationKey.IS_AKOODA_AI_FEATURE_ENABLED]: false,
  [ConfigurationKey.IS_COMPANY_EFFORTS_ENABLED]: true,
  [ConfigurationKey.SHOW_SUGGESTED_TAGS_KEYWORDS_AS_KEYWORD_OPTIONS]: false,
  [ConfigurationKey.IS_ENTERPRISE_SEARCH_ENABLED]: false,
  [ConfigurationKey.IS_SEARCH_RESULTS_IN_ENTERPRISE_SEARCH_ENABLED]: false,
  [ConfigurationKey.IS_EXTENSION_ENABLED]: false,
  [ConfigurationKey.IS_SALES_DEMO_MODE]: false,
  [ConfigurationKey.IS_PREPROCESSING_ENABLED]: false,
};

export default function ConfigurationContextProvider({ children }) {
  const { tenant } = useTenantContext();
  const [configurations, setConfigurations] = useState<ConfigurationsState>({});
  const loadedConfigurations = useRef<ConfigurationKey[]>([]);

  const fetchConfiguration = useCallback(
    async (key: ConfigurationKey) => {
      loadedConfigurations.current.push(key);
      try {
        const response = await Api.Configuration.getConfiguration(
          key,
          tenant.id,
        );
        DebuggerConsole.log(
          `Successfully fetched config "${key}" with value "${response?.data}"`,
        );
        setConfigurations((prev) => ({
          ...prev,
          [key]: response?.data,
        }));
      } catch (error) {
        DebuggerConsole.error(
          'Failed to fetch configuration. Fall back to default value.',
          { key, tenantId: tenant.id },
          error,
        );
        setConfigurations((prev) => ({
          ...prev,
          [key]: defaultConfigValues[key],
        }));
      }
    },
    [setConfigurations, tenant],
  );

  const getConfiguration = useCallback(
    <T extends ConfigType>(key: ConfigurationKey) => {
      const value = configurations[key];
      if (value === undefined && !loadedConfigurations.current.includes(key)) {
        fetchConfiguration(key);
      }
      return value as T | undefined;
    },
    [configurations, fetchConfiguration],
  );

  return (
    <ConfigurationContext.Provider
      value={{
        getConfiguration,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
}
